.MuiImageListItemBar-root {
    background: rgba(255, 255, 255, 0.75) !important;
}


.MuiImageListItemBar-titleWrap {
    color: #2e2e2e !important;
    font-weight: bold;
    text-align: center;
}

.cardContainer {
    margin: 0px !important;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin: 10px 0px 10px 20px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

cardImage {
  border-radius: 5px 5px 5px 5px;
  width: 100%;
}

